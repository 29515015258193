import { useCanvasNodesStore } from "../stores/CanvasNodesStore";
import { useCanvasReferencesStore } from "../stores/CanvasReferences";
import { useSelectedCanvasNodeStore } from "../stores/SelectedCanvasNodeStore";

export function undoCanvasChanges() {
	const selectNode = useSelectedCanvasNodeStore.getState().setSelectedNodeId;
	const transformerRef = useCanvasReferencesStore.getState().transformerRef;
	const undoActions = useCanvasNodesStore.temporal.getState().undo;
	transformerRef?.nodes([]);
	undoActions();
	selectNode(null);
}

/** @jsxImportSource @emotion/react */

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Box,
  CircularProgress,
  dialogClasses,
  Divider,
  Fade,
  Skeleton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import SwiperCore, { Virtual, Mousewheel } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import ResponsiveDialog from "../../../../../components/shared/ResponsiveDialog";
import { useCreations } from "../../../queries/notFoundCreationsQueries";
import { Virtuoso } from "react-virtuoso";
import { useWindowHeight } from "../hooks/useWindowHeight";
import SomethingWentWrong from "./SomethingWentWrong";
import NoCreationsYet from "./NoCreationsYet";
import { Creation } from "mabiloft-api-manager";
import { L } from "../../../../../traductions";

SwiperCore.use([Virtual]);

export const ItemComponent = styled(Stack)(({ theme }) => ({
  width: "100%",
  alignItems: "center",
}));

type CreationCardProps =
  | {
      image: string;
      title: string;
      creatorName: string;
      description: string;
      isLoading?: never;
    }
  | {
      isLoading: true;
      image?: never;
      title?: never;
      creatorName?: never;
      description?: never;
    };

const CreationCard: React.FC<CreationCardProps> = ({
  image,
  title,
  description,
  creatorName,
  isLoading,
}) => {
  return (
    <Stack
      alignItems={"flex-start"}
      sx={{
        width: "100%",
        position: "relative",
        maxWidth: { xs: 500, md: "unset" },
        paddingBottom: {
          xs: 10,
          md: "unset",
        },
        height: "100%",
        boxSizing: "border-box",
        userSelect: "none",
      }}
      gap={8}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 6,
          overflow: "hidden",
          flexShrink: 0,
        }}
      >
        {isLoading ? (
          <Skeleton
            sx={{
              borderRadius: 6,
              paddingTop: "100%",
              transform: "unset",
            }}
          />
        ) : (
          <Box
            sx={{
              borderRadius: 6,
              paddingTop: "100%",
              backgroundColor: "red",
              background: `url(${image})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        )}
      </Box>
      <Stack gap={2} alignItems="flex-start" sx={{ width: "100%" }}>
        {isLoading ? (
          <Skeleton width={"70%"}>
            <Typography variant="h5" children={"."} fontWeight={700} />
          </Skeleton>
        ) : (
          <Typography
            variant="h5"
            children={
              isLoading ? (
                <Skeleton width={"70%"} />
              ) : (
                `${creatorName} - ${title}`
              )
            }
            fontWeight={700}
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "3",
              wordBreak: "break-word",
              whiteSpace: "pre-wrap",
            }}
          />
        )}
        {isLoading ? (
          <Skeleton width={"60%"}>
            <Typography variant="body1" children={"."} />
          </Skeleton>
        ) : (
          <Typography
            variant="body1"
            children={description}
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              wordBreak: "break-word",
              whiteSpace: "pre-wrap",
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

const OtherCreationsCarousel = NiceModal.create(() => {
  const { visible, hide } = useModal();

  const {
    data: creations,
    hasNextPage: hasMoreCreations,
    fetchNextPage: fetchMoreCreations,
    isFetchingNextPage: isFetchingMoreCreations,
    isLoading: isFetchingCreations,
    isError: isFetchingCreationsError,
  } = useCreations();

  const creationsArray = useMemo(() => {
    return (
      creations?.pages.reduce(
        (acc, page) => [...acc, ...(page.data ?? [])],
        [] as Creation[]
      ) ?? []
    );
  }, [creations]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const loadOnEnd = useCallback(() => {
    if (hasMoreCreations) {
      fetchMoreCreations();
    }
  }, [hasMoreCreations, fetchMoreCreations]);

  const windowHeight = useWindowHeight();

  return (
    <ResponsiveDialog
      isOpen={visible}
      onClose={hide}
      title={L("notFound.otherCreations")}
      DialogProps={{
        maxWidth: "lg",
        sx: {
          zIndex: 1600,
          [`& .${dialogClasses.paper}`]: {
            borderRadius: 6,
            backgroundColor: "#FEFBF7",
            width: "100%",
            position: "relative",
            height: "min(836px, 80vh)",
          },
        },
      }}
      css={{
        "& [data-rsbs-content]": {
          padding: "16px 0px",
          paddingBottom: "0px  !important",
          boxSizing: "border-box",
        },
      }}
    >
      {isFetchingCreationsError ? (
        <SomethingWentWrong />
      ) : creationsArray.length === 0 && !isFetchingCreations ? (
        <NoCreationsYet />
      ) : isMd ? (
        <Swiper
          spaceBetween={32}
          slidesPerView={windowHeight <= 800 ? 4 : windowHeight <= 1000 ? 3 : 2}
          slidesPerGroup={
            windowHeight <= 800 ? 4 : windowHeight <= 1000 ? 3 : 2
          }
          virtual={{
            enabled: true,
            addSlidesBefore: 5,
            addSlidesAfter: 5,
          }}
          onReachEnd={loadOnEnd}
          modules={[Mousewheel]}
          mousewheel
          css={{
            width: "100%",
            flexGrow: 1,
            paddingBottom: "80px !important",
            paddingLeft: "40px !important",
            paddingRight: "40px !important",
            boxSizing: "border-box",
            cursor: "pointer",
          }}
        >
          {isFetchingCreations
            ? [...new Array(3)].map((_, index) => {
                return (
                  <SwiperSlide key={`loading-slide-${index}`}>
                    <CreationCard isLoading />
                  </SwiperSlide>
                );
              })
            : creationsArray.map((slide, index) => {
                return (
                  <SwiperSlide key={slide._id}>
                    <CreationCard
                      creatorName={slide.author ?? ""}
                      description={slide.description ?? ""}
                      image={slide.imageUrl ?? ""}
                      title={slide.title ?? ""}
                    />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      ) : (
        <Box data-body-scroll-lock-ignore>
          <Virtuoso
            data={isFetchingCreations ? [...new Array(3)] : creationsArray}
            style={{
              height: "75vh",
            }}
            endReached={loadOnEnd}
            overscan={5}
            components={{
              Item: ItemComponent,
            }}
            itemContent={(index, slide) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    px: 6,
                    maxWidth: 500,
                    paddingBottom: index === creationsArray.length - 1 ? 0 : 12,
                    boxSizing: "border-box",
                  }}
                >
                  {isFetchingCreations ? (
                    <CreationCard isLoading />
                  ) : (
                    <>
                      <CreationCard
                        creatorName={slide.author ?? ""}
                        description={slide.description ?? ""}
                        image={slide.imageUrl ?? ""}
                        title={slide.title ?? ""}
                      />
                      {index !== creationsArray.length - 1 && <Divider />}
                    </>
                  )}
                </Box>
              );
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: { xs: "unset", md: "50%" },
          bottom: { xs: 30, md: "unset" },
          right: { xs: "unset", md: 40 },
          left: { xs: "50%", md: "unset" },
          transform: {
            xs: "translateX(-50%)",
            md: "translateY(-50%)",
          },
          zIndex: 2,
        }}
      >
        <Fade in={isFetchingMoreCreations}>
          <Stack
            alignItems={"center"}
            justifyContent="center"
            sx={{
              backgroundColor: "white",
              boxShadow: "0px 1px 12px 0px rgba(0, 0, 0, 0.12)",
              width: 50,
              height: 50,
              borderRadius: "100%",
            }}
          >
            <CircularProgress color="secondary" size={32} />
          </Stack>
        </Fade>
      </Box>
    </ResponsiveDialog>
  );
});
export default OtherCreationsCarousel;

import { useEffect, useState } from "react";

export function useWindowHeight() {
	const [height, setHeight] = useState(
		typeof window !== "undefined" ? window.innerHeight : 1
	);

	useEffect(() => {
		const handleResize = () => {
			setHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return height;
}

import produce from "immer";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { availableNodeShapes } from "../constants/availableShapes";
import { useCanvasReferencesStore } from "./CanvasReferences";
import {
	useSelectCanvasNode,
	useSelectedCanvasNodeId,
} from "./SelectedCanvasNodeStore";
import { useSelectedCanvasOptionStore } from "./SelectedCanvasOption";
import { temporal } from "zundo";

export type CanvasNodeType = {
	id: string;
	type: typeof availableNodeShapes[number];
	color: string;
	rotation: number;
	width: number;
	height: number;
	x: number;
	y: number;
};

type NodesStoreType = {
	addNode: (newNode: CanvasNodeType) => void;
	deleteNode: (id: string) => void;
	editNode: <T extends CanvasNodeType>(
		nodeId: string,
		newNode: Partial<T>
	) => void;
	resetNodes: () => void;
	nodes: {
		[x: string]: CanvasNodeType;
	};
};

export const useCanvasNodesStore = create<NodesStoreType>()(
	temporal((set, get) => ({
		nodes: {},
		replaceNodes: (newNodes: { [x: string]: CanvasNodeType }) => {
			set(
				produce((state) => {
					state.nodes = newNodes;
				})
			);
		},
		addNode: (newNode) => {
			set(
				produce((state) => {
					state.nodes[newNode.id] = newNode;
				})
			);
		},
		editNode: (id, newNode) => {
			set(
				produce((state) => {
					state.nodes[id] = {
						...get().nodes[id],
						...newNode,
					};
				})
			);
		},
		deleteNode: (id) => {
			set(
				produce((state) => {
					delete state.nodes[id];
				})
			);
		},
		resetNodes: () => {
			set({ nodes: {} });
		},
	}))
);

export function useCanvasNodeByIdWithSelector<T extends CanvasNodeType>() {
	function useInner<X extends (keyof T)[]>(
		id: string,
		selectedProps: X
	): Pick<T, X[number]> | undefined {
		return useCanvasNodesStore((state) => {
			return selectedProps.reduce((acc, prop) => {
				return { ...acc, [prop]: (state.nodes[id] as T)?.[prop] };
			}, {}) as Pick<T, X[number]> | undefined;
		}, shallow);
	}
	return useInner;
}

export function useCanvasNodeById(id: string) {
	return useCanvasNodesStore((state) => state.nodes[id]) as
		| CanvasNodeType
		| undefined;
}

export function useEditCanvasNode() {
	return useCanvasNodesStore((state) => state.editNode);
}

export function useAddCanvasNode() {
	return useCanvasNodesStore((state) => state.addNode);
}

export function useDeleteCanvasNode() {
	return useCanvasNodesStore((state) => state.deleteNode);
}

export function useCanvasNodeIds() {
	return useCanvasNodesStore((state) => {
		return Object.keys(state.nodes);
	});
}

function useResetNodes() {
	return useCanvasNodesStore((state) => state.resetNodes);
}

export function useResetCanvas() {
	const resetNodes = useResetNodes();
	const transformerRef = useCanvasReferencesStore(
		(state) => state.transformerRef
	);
	const selectCanvasNode = useSelectCanvasNode();
	const selectCanvasColor = useSelectedCanvasOptionStore(
		(state) => state.setSelectedColor
	);
	const selectCanvasShape = useSelectedCanvasOptionStore(
		(state) => state.setSelectedShape
	);

	return () => {
		resetNodes();
		transformerRef?.nodes([]);
		selectCanvasNode(null);
		selectCanvasColor("rgba(255, 122, 0, 1)");
		selectCanvasShape("square");
	};
}

import { Box, Fade, Stack } from "@mui/material";
import { shallow } from "zustand/shallow";
import { useSelectedCanvasNodeStore } from "../Canvas/stores/SelectedCanvasNodeStore";
import ColorSelector from "./components/ColorSelector";
import DeleteShapeButton from "./components/DeleteShapeButton";
import GoBackButton from "./components/GoBackButton";
import ShapeSelector from "./components/ShapeSelector";

type CanvasMenuProps = {};
const CanvasMenu: React.FC<CanvasMenuProps> = ({}) => {
	const isOneNodeSelected = useSelectedCanvasNodeStore((state) => {
		return state.selectedNodeId !== null;
	}, shallow);

	return (
		<>
			<ShapeSelector
				sx={{ position: "absolute", top: 24, left: 24, zIndex: 2 }}
			/>
			<Stack
				direction={"row"}
				alignItems={"center"}
				gap={3}
				sx={{
					position: "absolute",
					top: 24,
					right: 24,
					height: 46,
					zIndex: 2,
				}}
			>
				<Fade in={isOneNodeSelected}>
					<Box>
						<DeleteShapeButton />
					</Box>
				</Fade>
				<GoBackButton />
				<ColorSelector />
			</Stack>
		</>
	);
};
export default CanvasMenu;

/* tslint:disable */
/* eslint-disable */
/**
 * Mabiloft Creations
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from './base';
/**
 * CreationsApi - axios parameter creator
 * @export
 */
export const CreationsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get images
         * @param {string} key
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImages: (key, page, perPage, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getImages', 'key', key);
            const localVarPath = `/{key}/get-images`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Upload image
         * @param {string} key
         * @param {string} author
         * @param {string} title
         * @param {string} description
         * @param {Array<File>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: (key, author, title, description, files, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('uploadImage', 'key', key);
            // verify required parameter 'author' is not null or undefined
            assertParamExists('uploadImage', 'author', author);
            // verify required parameter 'title' is not null or undefined
            assertParamExists('uploadImage', 'title', title);
            // verify required parameter 'description' is not null or undefined
            assertParamExists('uploadImage', 'description', description);
            const localVarPath = `/{key}/upload-image`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            if (author !== undefined) {
                localVarFormParams.append('author', author);
            }
            if (title !== undefined) {
                localVarFormParams.append('title', title);
            }
            if (description !== undefined) {
                localVarFormParams.append('description', description);
            }
            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element);
                });
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * CreationsApi - functional programming interface
 * @export
 */
export const CreationsApiFp = function (configuration) {
    const localVarAxiosParamCreator = CreationsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get images
         * @param {string} key
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImages(key, page, perPage, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getImages(key, page, perPage, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Upload image
         * @param {string} key
         * @param {string} author
         * @param {string} title
         * @param {string} description
         * @param {Array<File>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(key, author, title, description, files, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.uploadImage(key, author, title, description, files, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * CreationsApi - factory interface
 * @export
 */
export const CreationsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = CreationsApiFp(configuration);
    return {
        /**
         *
         * @summary Get images
         * @param {string} key
         * @param {string} [page]
         * @param {string} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImages(key, page, perPage, options) {
            return localVarFp.getImages(key, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Upload image
         * @param {string} key
         * @param {string} author
         * @param {string} title
         * @param {string} description
         * @param {Array<File>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(key, author, title, description, files, options) {
            return localVarFp.uploadImage(key, author, title, description, files, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CreationsApi - object-oriented interface
 * @export
 * @class CreationsApi
 * @extends {BaseAPI}
 */
export class CreationsApi extends BaseAPI {
    /**
     *
     * @summary Get images
     * @param {string} key
     * @param {string} [page]
     * @param {string} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreationsApi
     */
    getImages(key, page, perPage, options) {
        return CreationsApiFp(this.configuration).getImages(key, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Upload image
     * @param {string} key
     * @param {string} author
     * @param {string} title
     * @param {string} description
     * @param {Array<File>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreationsApi
     */
    uploadImage(key, author, title, description, files, options) {
        return CreationsApiFp(this.configuration).uploadImage(key, author, title, description, files, options).then((request) => request(this.axios, this.basePath));
    }
}

import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import { CreationsApi } from "mabiloft-api-manager";
import { instanceApi } from "../../../components/utils/api";

const creationsKey = process.env.GATSBY_404_IMAGE_KEY ?? "";

export const useCreations = () => {
	return useInfiniteQuery({
		queryKey: ["creations"],
		queryFn: async ({ pageParam }) => {
			return instanceApi(CreationsApi).getImages(creationsKey, pageParam);
		},
		getNextPageParam: (lastPage, pages) => {
			const totalImages = parseInt(lastPage.headers["x-total-count"]);
			const alreadyFetchedImages = pages.reduce(
				(count, page) => count + page.data.length,
				0
			);
			if (alreadyFetchedImages < totalImages) return pages.length;
			return false;
		},
	});
};

export const useUploadImage = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (params: {
			author: string;
			title: string;
			description: string;
			file: File;
		}) => {
			return instanceApi(CreationsApi).uploadImage(
				creationsKey,
				params.author,
				params.title,
				params.description,
				[params.file]
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["creations"]);
		},
	});
};

import { create } from "zustand";

type SelectedCanvasNodeStoreType = {
	selectedNodeId: string | null;
	setSelectedNodeId: (id: string | null) => void;
};

export const useSelectedCanvasNodeStore = create<SelectedCanvasNodeStoreType>()(
	(set) => ({
		selectedNodeId: null,
		setSelectedNodeId: (id) => {
			set(() => ({
				selectedNodeId: id,
			}));
		},
	})
);

export function useSelectCanvasNode() {
	return useSelectedCanvasNodeStore((state) => state.setSelectedNodeId);
}

export function useSelectedCanvasNodeId() {
	return useSelectedCanvasNodeStore((state) => state.selectedNodeId);
}

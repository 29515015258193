import { IconButton } from "@mui/material";
import * as React from "react";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { undoCanvasChanges } from "../../Canvas/functions/undoCanvasChanges";
import { useCanvasNodesStore } from "../../Canvas/stores/CanvasNodesStore";

function BackIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M4.446 7.218c-.125.124-.09.226.087.226h11.868c3.645 0 6.599 2.954 6.599 6.6 0 3.646-2.95 6.6-6.6 6.6h-5.967a.943.943 0 110-1.886H16.4a4.71 4.71 0 004.713-4.714 4.713 4.713 0 00-4.713-4.714H4.533c-.173 0-.216.097-.087.225l2.25 2.251a.942.942 0 01-1.332 1.335l-4.09-4.09a.94.94 0 010-1.329l4.09-4.09a.94.94 0 011.332.002.945.945 0 010 1.333l-2.25 2.25z"
				fill="#000"
			/>
		</svg>
	);
}

const useTemporalNodesStore = create(useCanvasNodesStore.temporal);

type GoBackButtonProps = {};
const GoBackButton: React.FC<GoBackButtonProps> = ({}) => {
	const canUndo = useTemporalNodesStore(
		(state) => state.pastStates.length > 0,
		shallow
	);

	return (
		<IconButton
			disabled={!canUndo}
			onClick={() => {
				undoCanvasChanges();
			}}
		>
			<BackIcon opacity={!canUndo ? 0.4 : 1} />
		</IconButton>
	);
};
export default GoBackButton;

import * as React from "react";

function PillMenuIcon(
	props: React.SVGProps<SVGSVGElement> & { fillColor?: string }
) {
	const { fillColor, ...rest } = props;

	return (
		<svg
			width={30}
			height={30}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<rect
				x={5}
				y={17.227}
				width={19}
				height={11}
				rx={5.5}
				transform="rotate(-44.12 5 17.227)"
				fill={fillColor || "#FF7A00"}
			/>
		</svg>
	);
}

export default PillMenuIcon;

import { create } from "zustand";
import { availableNodeShapes } from "../constants/availableShapes";

type SelectedCanvasOptionStoreType = {
	selectedShape: typeof availableNodeShapes[number];
	setSelectedShape: (newShape: typeof availableNodeShapes[number]) => void;
	selectedColor: string;
	setSelectedColor: (newColor: string) => void;
};

export const useSelectedCanvasOptionStore = create<
	SelectedCanvasOptionStoreType
>()((set) => ({
	selectedShape: availableNodeShapes[0],
	setSelectedShape: (newShape) => set({ selectedShape: newShape }),
	selectedColor: "rgba(255, 122, 0, 1)",
	setSelectedColor: (newColor) => set({ selectedColor: newColor }),
}));

export function useSelectedCanvasShape() {
	const { selectedShape, setSelectedShape } = useSelectedCanvasOptionStore();
	return { selectedShape, setSelectedShape };
}

export function useSelectedCanvasColor() {
	const { selectedColor, setSelectedColor } = useSelectedCanvasOptionStore();
	return { selectedColor, setSelectedColor };
}

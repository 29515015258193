import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Box, Stack, styled, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import ResponsiveDialog from "../../../../../components/shared/ResponsiveDialog";
import { L } from "../../../../../traductions";
import { useUploadImage } from "../../../queries/notFoundCreationsQueries";
import OutlinedButton from "../../../../../components/shared/OutlinedButton";

type UploadYourCreationDialogProps = {
  fileToUpload?: File;
};

const BiggerLabelTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    fontSize: 20,
    transform: "translateY(12px)",
    "&.Mui-focused": {
      transform: "translateY(0px) scale(0.75)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: "translateY(0px) scale(0.75)",
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: "100%",
  },
  "& .MuiInputBase-root": {
    "&::before": {
      borderWidth: "1px !important",
    },
    "&::after": {
      borderWidth: "1px !important",
    },
  },
}));

const UploadYourCreationDialog = NiceModal.create(
  ({ fileToUpload }: UploadYourCreationDialogProps) => {
    const { visible, hide, resolve } = useModal();
    const [imageUrl] = useState(() => {
      if (fileToUpload) {
        return URL.createObjectURL(fileToUpload);
      }
    });

    useEffect(() => {
      return () => {
        if (imageUrl) {
          URL.revokeObjectURL(imageUrl);
        }
      };
    }, []);

    const uploadImageMutation = useUploadImage();

    const [formState, setFormState] = useImmer({
      name: {
        value: "",
        error: false,
      },
      creationName: {
        value: "",
        error: false,
      },
      description: {
        value: "",
        error: false,
      },
    });

    return (
      <ResponsiveDialog
        isOpen={visible}
        DialogProps={{ maxWidth: "md" }}
        onClose={uploadImageMutation.isLoading ? () => {} : hide}
      >
        <Stack
          sx={{
            px: { xs: 0, md: 10 },
            pb: { xs: 8, md: 10 },
            gap: { xs: 6, md: 10 },
          }}
        >
          <Stack
            gap={10}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              width: "100%",
            }}
          >
            <Box
              sx={{
                flex: 1,
                maxWidth: 174,
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  pt: "100%",
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: "cover",
                  backgroundColor: "white",
                  borderRadius: 2,
                }}
              />
            </Box>
            <Stack gap={6} sx={{ flex: 1, width: "100%" }}>
              <BiggerLabelTextField
                variant="standard"
                label={L("notFound.yourName")}
                fullWidth
                value={formState.name.value}
                error={formState.name.error}
                helperText={
                  formState.name.error && L("notFound.insertYourName")
                }
                onChange={(e) => {
                  setFormState((draft) => {
                    draft.name.error = false;
                    draft.name.value = e.target.value;
                  });
                }}
                onBlur={() => {
                  setFormState((draft) => {
                    draft.name.error = !draft.name.value;
                  });
                }}
              />
              <BiggerLabelTextField
                variant="standard"
                label={L("notFound.yourCreationName")}
                fullWidth
                value={formState.creationName.value}
                error={formState.creationName.error}
                helperText={
                  formState.creationName.error &&
                  L("notFound.insertYourCreationName")
                }
                onChange={(e) => {
                  setFormState((draft) => {
                    draft.creationName.error = false;
                    draft.creationName.value = e.target.value;
                  });
                }}
                onBlur={() => {
                  setFormState((draft) => {
                    draft.creationName.error = !draft.creationName.value;
                  });
                }}
              />
              <BiggerLabelTextField
                variant="standard"
                label={L("notFound.wantAddADescription")}
                fullWidth
                value={formState.description.value}
                onChange={(e) => {
                  setFormState((draft) => {
                    draft.description.value = e.target.value;
                  });
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: { xs: "center", md: "flex-end" },
              marginTop: { xs: 12, md: 10 },
            }}
          >
            <OutlinedButton
              isLoading={uploadImageMutation.isLoading}
              onClick={() => {
                if (fileToUpload) {
                  uploadImageMutation.mutate(
                    {
                      author: formState.name.value,
                      title: formState.creationName.value,
                      description: formState.description.value,
                      file: fileToUpload,
                    },
                    {
                      onSuccess: () => {
                        resolve();
                        hide();
                        NiceModal.show("SuccessSnackbar", {
                          message: L("notFound.thanksForShare"),
                          status: "success",
                        });
                      },
                      onError: () => {
                        NiceModal.show("SuccessSnackbar", {
                          message: L("notFound.somethingWentWrong"),
                          status: "error",
                        });
                      },
                    }
                  );
                }
              }}
              disabled={
                formState.creationName.value === "" ||
                formState.name.value === ""
              }
            >
              INVIA LA TUA BELLISSIMA OPERA
            </OutlinedButton>
          </Stack>
          <Typography
            children={L("notFound.weReserveDescription")}
            variant="body2"
            sx={{ opacity: 0.6 }}
          />
        </Stack>
      </ResponsiveDialog>
    );
  }
);
export default UploadYourCreationDialog;

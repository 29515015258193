import { createTheme, Theme } from "@mui/material";
import theme from "../../../layouts/theme";

const newTheme: Partial<Theme> = {
	typography: {
		...theme.typography,
		h1: {
			fontSize: 96,
			lineHeight: "93px",
			letterSpacing: "-2.5px",
			fontWeight: 700,
		},
		h2: {
			fontSize: 64,
			lineHeight: "87.3px",
			letterSpacing: "-0.5px",
			fontWeight: 700,
		},
		h3: {
			fontSize: 40,
			lineHeight: "44px",
			letterSpacing: "-2.5px",
			fontWeight: 700,
		},
		h4: {
			fontWeight: 700,
			fontSize: 36,
			paddingBottom: 16,
		},
		h5: {
			fontSize: 24,
			lineHeight: "32.7px",
			letterSpacing: "0.5px",
			fontWeight: 400,
		},
		subtitle1: {
			fontSize: 16,
			lineHeight: "20px",
			letterSpacing: "0.5px",
			fontWeight: 700,
		},
		body1: {
			fontSize: 16,
			lineHeight: "20px",
			letterSpacing: "0.5px",
			fontWeight: 400,
		},
		body2: {
			fontSize: 16,
			lineHeight: "18px",
			letterSpacing: "0.25px",
			fontWeight: 400,
		},
		button: {
			fontSize: 14,
			lineHeight: "18px",
			letterSpacing: "0.25px",
			fontWeight: 400,
		},
	},
	//@ts-ignore
	spacing: 4,
};

export const notFoundPageTheme = createTheme({ ...theme, ...newTheme });

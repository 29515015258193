import * as React from "react";

function RhombusMenuIcon(
	props: React.SVGProps<SVGSVGElement> & { fillColor?: string }
) {
	const { fillColor, ...rest } = props;

	return (
		<svg
			width={30}
			height={30}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M20.846 14.985L14.96 26.896 8.987 14.995 14.937 3l5.909 11.985z"
				fill={fillColor || "#FF7A00"}
			/>
		</svg>
	);
}

export default RhombusMenuIcon;

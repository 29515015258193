import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../components/old/seo_head";
import NotFoundPage from "../features/not_found_page/NotFoundPage";
import { L } from "../traductions";

export default NotFoundPage;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return <SEOHead title={L("pageNotFound")} headProps={headProps} />;
};

/** @jsxImportSource @emotion/react */

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import {
	Dialog,
	dialogClasses,
	DialogProps,
	IconButton,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useCallback } from "react";

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.357 7.815a.829.829 0 00-1.172-1.172L12 10.828 7.815 6.643a.829.829 0 10-1.172 1.172L10.828 12l-4.185 4.185a.829.829 0 001.172 1.173L12 13.173l4.185 4.185a.829.829 0 001.172-1.173L13.172 12l4.185-4.185z"
				fill="#0C1917"
			/>
		</svg>
	);
}

type ResponsiveDialogProps = {
	isOpen: boolean;
	onClose: () => void;
	title?: string;
	DialogProps?: Omit<DialogProps, "open" | "onClose" | "children">;
	className?: string;
};

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
	isOpen,
	onClose,
	title,
	children,
	DialogProps,
	className,
}) => {
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up("md"));

	const snapPoints = useCallback(
		({
			minHeight,
			maxHeight,
		}: {
			minHeight: number;
			maxHeight: number;
		}) => {
			return minHeight >= maxHeight ? [maxHeight * 0.9] : [minHeight];
		},
		[]
	);

	const header = (
		<Stack
			direction="row"
			alignItems={"center"}
			justifyContent={title ? "space-between" : "flex-end"}
			sx={{ p: { xs: 0, md: 10 } }}
		>
			{title && (
				<Typography
					children={title}
					sx={{
						typography: {
							xs: "subtitle1",
							md: "h5",
							fontWeight: "700 !important",
							textAlign: "start",
						},
					}}
				/>
			)}
			<IconButton onClick={onClose}>
				<CloseIcon />
			</IconButton>
		</Stack>
	);

	return isMd ? (
		<Dialog
			open={isOpen}
			onClose={onClose}
			sx={{
				zIndex: 1600,
				[`& .${dialogClasses.paper}`]: {
					borderRadius: 6,
					backgroundColor: "#FEFBF7",
				},
			}}
			className={className}
			{...DialogProps}
		>
			{header}
			{children}
		</Dialog>
	) : (
		<BottomSheet
			open={isOpen}
			header={header}
			className={className}
			css={{
				"& [data-rsbs-overlay]": {
					zIndex: 1600,
					backgroundColor: "#FEFBF7",
				},
				"& [data-rsbs-backdrop]": {
					zIndex: 1600,
				},
				"& [data-rsbs-header]": {
					padding: "16px 24px",
					paddingBottom: 0,
					boxShadow: "none",
					"&::before": {
						content: "none",
					},
				},
				"& [data-rsbs-content]": {
					padding: "16px 24px",
					paddingBottom: "16px  !important",
					boxSizing: "border-box",
				},
			}}
			snapPoints={snapPoints}
		>
			{children}
		</BottomSheet>
	);
};
export default ResponsiveDialog;

import * as React from "react";

function CircleMenuIcon(
	props: React.SVGProps<SVGSVGElement> & { fillColor?: string }
) {
	const { fillColor, ...rest } = props;
	return (
		<svg
			width={30}
			height={30}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<circle cx={15} cy={15} r={8} fill={fillColor || "#FF7A00"} />
		</svg>
	);
}

export default CircleMenuIcon;

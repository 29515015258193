import { Transformer } from "konva/lib/shapes/Transformer";
import { create } from "zustand";

type CanvasReferencesStoreType = {
	transformerRef: Transformer | null;
	setTransformerRef: (ref: Transformer | null) => void;
};

export const useCanvasReferencesStore = create<CanvasReferencesStoreType>()(
	(set) => ({
		transformerRef: null,
		setTransformerRef: (ref) => {
			set(() => ({
				transformerRef: ref,
			}));
		},
	})
);

import { Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { L } from "../../../../../traductions";

type NoCreationsYetProps = {};
const NoCreationsYet: React.FC<NoCreationsYetProps> = ({}) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent="center"
      gap={8}
      sx={{
        width: "100%",
        flexGrow: 1,
        paddingBottom: { xs: 8, md: 0 },
        zIndex: 40,
      }}
    >
      <StaticImage src="../../../images/no_content_illustation.png" alt="" />
      <Stack alignItems={"center"} justifyContent="center" gap={2}>
        <Typography
          children={L("notFound.theresNothingHere")}
          variant="h5"
          fontWeight={700}
          textAlign="center"
        />
        <Typography
          children={L("notFound.sendFirstCreation")}
          variant="body1"
          textAlign="center"
        />
      </Stack>
    </Stack>
  );
};
export default NoCreationsYet;

import NiceModal from "@ebay/nice-modal-react";
import loadable from "@loadable/component";
import {
  Box,
  CircularProgress,
  Collapse,
  Stack,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { shallow } from "zustand/shallow";
import ButtonWithUnderline from "../../../../components/shared/ButtonWithUnderline";
import { L } from "../../../../traductions";
import { CanvasRef } from "./components/Canvas/Canvas";
import { useCanvasNodesStore } from "./components/Canvas/stores/CanvasNodesStore";
import CanvasMenu from "./components/CanvasMenu/CanvasMenu";
import UploadYourCreationDialog from "./components/UploadYourCreationDialog";
const Canvas = loadable(() => import("./components/Canvas/Canvas"));

const CreationSection = () => {
  const squaredBoxRef = useRef(null);

  const { width = 1, height = 1 } = useResizeObserver<HTMLDivElement>({
    ref: squaredBoxRef,
    box: "border-box",
  });

  const canvasRef = useRef<CanvasRef>(null);

  const [exportingCanvasFileLoading, setExportingCanvasFileLoading] = useState(
    false
  );

  const canvasShapesCount = useCanvasNodesStore(
    (state) => Object.keys(state.nodes).length,
    shallow
  );

  const theme = useTheme();

  return (
    <>
      <Stack
        sx={{
          position: "relative",
          alignItems: { xs: "center", lg: "flex-end" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 544,
            position: "relative",
          }}
        >
          <Box
            ref={squaredBoxRef}
            sx={{
              paddingTop: "100%",
              backgroundColor: "white",
              borderRadius: 6,
            }}
          />
          <Box
            sx={{
              borderRadius: 6,
              overflow: "hidden",
              backgroundColor: "white",
              width: "100%",
              maxWidth: width,
              height,
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <Canvas ref={canvasRef} width={width} height={height} />
              <CanvasMenu />
            </Box>
          </Box>
        </Box>
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{
            marginTop: 8,
          }}
        >
          <Collapse in={exportingCanvasFileLoading} orientation="horizontal">
            <CircularProgress
              size={24}
              sx={{ marginRight: 2, marginTop: "2px" }}
            />
          </Collapse>
          <ButtonWithUnderline
            disabled={canvasShapesCount === 0}
            onClick={() => {
              setExportingCanvasFileLoading(true);
              canvasRef.current?.canvasToFile().then((file) => {
                setExportingCanvasFileLoading(false);
                if (file) {
                  NiceModal.show("upload-creation-dialog", {
                    fileToUpload: file,
                    key: file.name,
                  }).then(() => {
                    canvasRef.current?.clearCanvas();
                  });
                }
              });
            }}
            children={L("notFound.sendYourCreation")}
            textStyle={{
              typography: "h5",
              opacity: canvasShapesCount === 0 ? 0.6 : 1,
              transition: theme.transitions.create("opacity"),
            }}
          />
        </Stack>
      </Stack>
      <UploadYourCreationDialog id="upload-creation-dialog" />
    </>
  );
};
export default CreationSection;

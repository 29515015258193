import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { StaticImage } from "gatsby-plugin-image";
import ButtonWithUnderline from "../../../../../components/shared/ButtonWithUnderline";
import { L } from "../../../../../traductions";

type SomethingWentWrongProps = {};
const SomethingWentWrong: React.FC<SomethingWentWrongProps> = ({}) => {
  const queryClient = useQueryClient();
  return (
    <Stack
      alignItems={"center"}
      justifyContent="center"
      gap={8}
      sx={{
        width: "100%",
        flexGrow: 1,
        paddingBottom: { xs: 8, md: 0 },
        zIndex: 3,
        position: "relative",
      }}
    >
      <StaticImage
        src="../../../images/creations_error_illustration.png"
        alt=""
      />
      <Stack alignItems={"center"} justifyContent="center" gap={2}>
        <Typography
          children={L("notFound.somethingWentWrong")}
          variant="h5"
          fontWeight={700}
          textAlign="center"
        />
        <Typography
          children={L("notFound.itCanHappen")}
          variant="body1"
          textAlign="center"
        />
      </Stack>
      <ButtonWithUnderline
        children={L("notFound.tryAgain")}
        defaultUnderlined
        onClick={() => {
          queryClient.resetQueries(["creations"]);
        }}
      />
    </Stack>
  );
};
export default SomethingWentWrong;

import { AxiosRequestConfig } from "axios";

export function instanceApi<T>(xyz: T) {
  const axiosOptions: AxiosRequestConfig = {
    withCredentials: false,
  };

  //@ts-ignore
  const instance: InstanceType<T> = new xyz({
    basePath: `https://creations-404.mabiloft.com/api/v1`,
    baseOptions: axiosOptions,
  });

  return instance;
}

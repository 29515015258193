import NiceModal from "@ebay/nice-modal-react";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { LocalizedLink } from "../../../../components/LocalizedLink";
import { L } from "../../../../traductions";
import OutlinedButton from "../../../../components/shared/OutlinedButton";
import ResponsiveSnackbar from "../ResponsiveSnackbar";
import OtherCreationsCarousel from "./components/OtherCreationsCarousel";

NiceModal.register("OtherCreationsDialog", OtherCreationsCarousel);

type TextSectionProps = {};
const TextSection: React.FC<TextSectionProps> = ({}) => {
  const queryClient = useQueryClient();
  return (
    <Stack sx={{ gap: { xs: 4, lg: 14 }, alignSelf: "start" }}>
      <Typography
        children={L("notFound.title")}
        component={"h1"}
        sx={{
          typography: {
            xs: "h3",
            md: "h1",
          },
        }}
      />
      <Typography
        children={L("notFound.description")}
        component={"h5"}
        sx={{
          typography: {
            xs: "body1",
            md: "h5",
          },
        }}
      />
      <OutlinedButton
        onClick={() => {
          queryClient.resetQueries(["creations"]);
          NiceModal.show("OtherCreationsDialog");
        }}
        children={L("notFound.watchCreations")}
        sx={{
          width: "100%",
          maxWidth: "367px",
          display: { xs: "none", lg: "flex" },
        }}
      />
      <Typography variant="body1" sx={{ marginTop: { xs: 6, lg: 0 } }}>
        {L("notFound.backHomeIntro")}
        <LocalizedLink to={"/"}>
          <Typography
            variant="body1"
            component="span"
            sx={{
              color: (theme) => theme.palette.secondary.main,
              textDecoration: "underline",
              textDecorationColor: (theme) => theme.palette.secondary.main,
            }}
          >
            {L("notFound.backHome")}
          </Typography>
        </LocalizedLink>
      </Typography>
      <ResponsiveSnackbar
        id="SuccessSnackbar"
        message={L("notFound.thanksForShare")}
        keepMounted
      />
    </Stack>
  );
};
export default TextSection;

import { RadioGroup } from "@headlessui/react";
import { Box, FormLabel, Stack, SxProps, Theme } from "@mui/material";
import { L } from "../../../../../../../traductions";

import { availableShapesColor } from "../../Canvas/constants/availableShapesColor";
import {
  useCanvasNodesStore,
  useEditCanvasNode,
} from "../../Canvas/stores/CanvasNodesStore";
import { useSelectedCanvasNodeStore } from "../../Canvas/stores/SelectedCanvasNodeStore";
import { useSelectedCanvasColor } from "../../Canvas/stores/SelectedCanvasOption";

type ColorSelectorProps = { sx?: SxProps<Theme> };
const ColorSelector: React.FC<ColorSelectorProps> = ({ sx }) => {
  const { selectedColor, setSelectedColor } = useSelectedCanvasColor();
  const editNode = useEditCanvasNode();
  return (
    <RadioGroup
      as={Box}
      sx={sx}
      value={selectedColor}
      onChange={(color: string) => {
        setSelectedColor(color);
        const selectedNodeId = useSelectedCanvasNodeStore.getState()
          .selectedNodeId;
        const availableNodes = useCanvasNodesStore.getState().nodes;
        if (selectedNodeId && availableNodes[selectedNodeId]) {
          editNode(selectedNodeId, { color: color });
        }
      }}
    >
      <RadioGroup.Label
        as={FormLabel}
        sx={{
          position: "absolute",
          width: "1px",
          height: "1px",
          padding: 0,
          margin: "-1px",
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          borderWidth: 0,
        }}
      >
        {L("notFound.color")}
      </RadioGroup.Label>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"center"}
        gap={1}
      >
        {availableShapesColor.map((color) => {
          return (
            <RadioGroup.Option
              key={color}
              as={Box}
              value={color}
              sx={{
                cursor: "pointer",
                "&:focus-visible": {
                  outline: "none",
                },
              }}
            >
              {({ checked }) => (
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: color,
                    borderRadius: "100%",
                    border: "2px solid white",
                    transition: (theme) =>
                      theme.transitions.create("border-color"),
                    "&:hover": {
                      borderColor: checked ? "#000000" : color,
                    },
                    ...(checked && {
                      borderColor: "#000000",
                    }),
                  }}
                />
              )}
            </RadioGroup.Option>
          );
        })}
      </Stack>
    </RadioGroup>
  );
};
export default ColorSelector;

import { Listbox } from "@headlessui/react";
import {
  alpha,
  Box,
  Button,
  Fade,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Fragment } from "react";
import { L } from "../../../../../../../traductions";
import { availableNodeShapes } from "../../Canvas/constants/availableShapes";
import { useCanvasReferencesStore } from "../../Canvas/stores/CanvasReferences";
import { useSelectCanvasNode } from "../../Canvas/stores/SelectedCanvasNodeStore";
import {
  useSelectedCanvasColor,
  useSelectedCanvasShape,
} from "../../Canvas/stores/SelectedCanvasOption";
import ArrowDownIcon from "./CanvasMenuIcons/ArrowDownIcon";
import CircleMenuIcon from "./CanvasMenuIcons/CircleMenuIcon";
import CloveMenuIcon from "./CanvasMenuIcons/CloveMenuIcon";
import PillMenuIcon from "./CanvasMenuIcons/PillMenuIcon";
import RhombusMenuIcon from "./CanvasMenuIcons/RhombusMenuIcon";
import SquareMenuIcon from "./CanvasMenuIcons/SquareMenuIcon";

function switchMenuIcon(
  shape: typeof availableNodeShapes[number],
  fillColor: string
) {
  switch (shape) {
    case "circle":
      return <CircleMenuIcon fillColor={fillColor} />;
    case "square":
      return <SquareMenuIcon fillColor={fillColor} />;
    case "clove":
      return <CloveMenuIcon fillColor={fillColor} />;
    case "pill":
      return <PillMenuIcon fillColor={fillColor} />;
    case "rhombus":
      return <RhombusMenuIcon fillColor={fillColor} />;
    default:
      break;
  }
}

type ShapeSelectorProps = { sx?: SxProps<Theme> };
const ShapeSelector: React.FC<ShapeSelectorProps> = ({ sx }) => {
  const { selectedShape, setSelectedShape } = useSelectedCanvasShape();
  const { selectedColor } = useSelectedCanvasColor();
  const transformerRef = useCanvasReferencesStore(
    (state) => state.transformerRef
  );
  const selectNode = useSelectCanvasNode();
  const theme = useTheme();

  return (
    <Listbox
      value={selectedShape}
      onChange={(value) => {
        setSelectedShape(value);
      }}
      as={Box}
      sx={{ position: "relative", ...sx }}
    >
      {({ open }) => {
        return (
          <>
            <Listbox.Button
              as={Button}
              disableRipple
              onClick={() => {
                selectNode(null);
                transformerRef?.nodes([]);
              }}
              sx={{
                outline: "2px solid transparent",
                px: 3,
                transition: theme.transitions.create("outline-color"),
                backgroundColor: "#FEF5F2",
                "&:hover": {
                  backgroundColor: "#FCEAE4",
                },
                "&:focus-visible": {
                  outlineColor: theme.palette.secondary.main,
                },
              }}
            >
              {switchMenuIcon(selectedShape, selectedColor)}
              <ArrowDownIcon />
            </Listbox.Button>
            <Fade in={open}>
              <Box sx={{ position: "absolute" }}>
                <Listbox.Options
                  as={Box}
                  sx={{
                    marginTop: 1,
                    backgroundColor: "white",
                    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.06)",
                    borderRadius: 3,
                    p: 1,
                    "&:focus-visible": {
                      outline: "none",
                    },
                  }}
                >
                  {availableNodeShapes.map((shape) => {
                    return (
                      <Listbox.Option key={shape} value={shape} as={Fragment}>
                        {({ active }) => (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={2}
                            sx={{
                              p: "6px",
                              cursor: "pointer",
                              borderRadius: 2,
                              border: "2px solid transparent",
                              transition: (theme) =>
                                theme.transitions.create([
                                  "background-color",
                                  "border-color",
                                ]),
                              ...(active && {
                                backgroundColor: alpha("#000000", 0.12),
                              }),
                              "&:hover": {
                                backgroundColor: alpha("#000000", 0.12),
                              },
                            }}
                          >
                            {switchMenuIcon(shape, selectedColor)}
                            <Typography variant="body2">
                              {L(`notFound.shapes.${shape}`)}
                            </Typography>
                          </Stack>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Box>
            </Fade>
          </>
        );
      }}
    </Listbox>
  );
};
export default ShapeSelector;

/** @jsxImportSource @emotion/react */

import { darken, IconButton, SxProps, Theme } from "@mui/material";
import { forwardRef } from "react";
import { useDeleteCanvasNode } from "../../Canvas/stores/CanvasNodesStore";
import { useCanvasReferencesStore } from "../../Canvas/stores/CanvasReferences";
import {
	useSelectCanvasNode,
	useSelectedCanvasNodeId,
} from "../../Canvas/stores/SelectedCanvasNodeStore";

function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="prefix__delete"
				style={{
					maskType: "alpha",
				}}
				maskUnits="userSpaceOnUse"
				x={3}
				y={2}
				width={20}
				height={20}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.819 5.489a2.384 2.384 0 01-.128-.427l-.243-1.216c-.051-.185-.262-.346-.502-.346h-4.233a.53.53 0 00-.512.392l-.233 1.17a2.38 2.38 0 01-.128.427h5.979zm-8.206 0h.005a.901.901 0 00.88-.722l.243-1.216C8.988 2.619 9.795 2 10.713 2h4.233c.918 0 1.725.619 1.962 1.506l.254 1.261a.899.899 0 00.883.722h3.263a.75.75 0 010 1.5H4.35a.75.75 0 010-1.5h3.263zm5.234 16.51c-1.355 0-2.676-.014-3.984-.041-1.672-.033-2.829-1.117-3.018-2.83-.315-2.84-.854-9.533-.859-9.6a.75.75 0 01.687-.808c.407-.011.775.275.808.687.005.068.543 6.739.855 9.557.107.973.632 1.475 1.558 1.494 2.5.053 5.051.056 7.801.006.985-.02 1.517-.511 1.627-1.507.288-2.602.776-8.58.847-9.446l.008-.104c.034-.412.399-.7.808-.687a.751.751 0 01.688.808l-.01.104c-.07.867-.56 6.868-.85 9.49-.194 1.747-1.348 2.81-3.09 2.842-1.333.023-2.62.036-3.876.036z"
					fill="#fff"
				/>
			</mask>
			<g mask="url(#prefix__delete)">
				<path fill="rgba(255, 59, 48, 1)" d="M1-.061h24v24H1z" />
			</g>
		</svg>
	);
}

type DeleteShapeButtonProps = { sx?: SxProps<Theme> };
const DeleteShapeButton = forwardRef<any, DeleteShapeButtonProps>(
	({ sx }, ref) => {
		const deleteShape = useDeleteCanvasNode();
		const selectedCanvasNodeId = useSelectedCanvasNodeId();
		const transformerRef = useCanvasReferencesStore(
			(state) => state.transformerRef
		);
		const selectCanvasNode = useSelectCanvasNode();

		return (
			<IconButton
				ref={ref}
				onClick={() => {
					if (selectedCanvasNodeId) {
						transformerRef?.nodes([]);
						deleteShape(selectedCanvasNodeId);
						selectCanvasNode(null);
					}
				}}
				sx={{
					backgroundColor: "white",
					boxShadow: "0px 1px 12px 0px rgba(0, 0, 0, 0.12)",
					"&:hover": {
						backgroundColor: darken("#FFFFFF", 0.1),
					},
					...sx,
				}}
			>
				<DeleteIcon css={{ transform: "translateX(-1px)" }} />
			</IconButton>
		);
	}
);
export default DeleteShapeButton;

import * as React from "react";

function SquareMenuIcon(
	props: React.SVGProps<SVGSVGElement> & { fillColor?: string }
) {
	const { fillColor, ...rest } = props;

	return (
		<svg
			width={30}
			height={30}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path fill={fillColor || "#FF7A00"} d="M7 7h16v16H7z" />
		</svg>
	);
}

export default SquareMenuIcon;

import * as React from "react";

function ArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={17}
			height={16}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="prefix__a"
				style={{
					maskType: "alpha",
				}}
				maskUnits="userSpaceOnUse"
				x={3}
				y={5}
				width={11}
				height={6}
			>
				<path
					d="M3.327 5.164a.54.54 0 01.715-.054l.061.054L8.5 9.649l4.397-4.485a.54.54 0 01.714-.054l.062.054c.194.199.212.51.053.729l-.053.062-4.785 4.881a.54.54 0 01-.714.054l-.062-.054-4.785-4.88a.568.568 0 010-.792z"
					fill="#fff"
				/>
			</mask>
			<g mask="url(#prefix__a)">
				<path fill="#000" d="M.5-.04h16v16H.5z" />
			</g>
		</svg>
	);
}

export default ArrowDownIcon;

import * as React from "react";

function CloveMenuIcon(
	props: React.SVGProps<SVGSVGElement> & { fillColor?: string }
) {
	const { fillColor, ...rest } = props;

	return (
		<svg
			width={30}
			height={30}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M10.765 7a9.287 9.287 0 0012.724 13.53l-6.362-6.765L10.765 7z"
				fill={fillColor || "#FF7A00"}
			/>
		</svg>
	);
}

export default CloveMenuIcon;
